import React, { Component } from 'react';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    lastname: '',
    events: '',
    notes: '',
    message: '', // Added message to state
    error: {},
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = '';

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  subimtHandler = (e) => {
    e.preventDefault();

    const { name, email, subject, lastname, events, notes, message, error } = this.state;

    // Basic validation
    if (name === '') error.name = "Please enter your name";
    if (email === '') error.email = "Please enter your email";
    else if (!this.validateEmail(email)) error.email = "Invalid email format";
    if (subject === '') error.subject = "Please enter your subject";
    if (lastname === '') error.lastname = "Please enter your Lastname";
    if (events === '') error.events = "Select your event list";
    if (notes === '') error.notes = "Please enter your note";
    if (message === '') error.message = "Please enter your message";

    // Set error state if any errors exist
    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      // Handle form submission (e.g., send data to backend)
      // Reset form
      this.setState({
        name: '',
        email: '',
        subject: '',
        lastname: '',
        events: '',
        notes: '',
        message: '',
        error: {},
      });
      alert("Form submitted successfully!");
    }
  };

  render() {
    const { name, email, subject, lastname, message, error } = this.state;

    return (
      <form onSubmit={this.subimtHandler} className="form">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name" />
              <p>{error.name ? error.name : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname" />
              <p>{error.lastname ? error.lastname : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email" />
              <p>{error.email ? error.email : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Subject" />
              <p>{error.subject ? error.subject : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <textarea value={message} onChange={this.changeHandler} name="message" placeholder="Message"></textarea>
              <p>{error.message ? error.message : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn">Send Message</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ContactForm;
