import React from 'react';

// Import the forum image
import prjImg1 from '../../images/forums.jpg';

// Project data array
const projects = [
    {
        image: prjImg1,
        title: "The Annual Internal Audit Forum",
        description: [
            "International Internal Auditing Standards issued by the Institute of Internal Auditors (IIA) Its New Framework, Remote Practice, the Role of Artificial Intelligence in its Application, and its Role in Achieving Cybersecurity Requirements",
            "In light of the rapid developments in the business and technology sectors, as well as the crises and disasters that have impacted the world in recent years—greatly affecting various professions, including internal auditing—the Institute of Internal Auditors (IIA) has restructured the internal audit standards to keep pace with these changes and developments. This forum was organized to address three critical papers relevant to the field of internal auditing:",
        ],
        bulletPoints: [
            "The 2024 Global Internal Audit Standards and the recommended new amendments to be implemented starting in 2025.",
            "Remote auditing and the role of artificial intelligence in enhancing the quality of the auditing process in the context of information technology and Blockchain applications.",
            "Internal auditing and its role in meeting cybersecurity requirements.",
        ],
        additionalInfo: [
            "This forum was attended by a range of internal and external audit managers, heads of internal audit committees, internal and external auditors, as well as internal and external quality assessors and performance evaluators from various ministries and government agencies. The event was led by a group of international experts in internal auditing."
        ],
        location: "Muscat, Sultanate of Oman",
        date: "29 Apr 2024",
        duration: "3 days",
    }
];

const ServiceSingleSection = () => {
    // Current project details (only one project here)
    const { image, title, description, bulletPoints, additionalInfo, location, date, duration } = projects[0];

    return (
        <section className="wpo-project-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-content">
                                <img src={image} alt="Project" />
                                <div className="wpo-project-single-content-des">
                                    <div className="wpo-project-single-content-des-wrap">
                                        <div className="wpo-project-single-content-des-left">
                                            <h2>{title}</h2>
                                            {description.map((para, index) => (
                                                <p key={index}>{para}</p>
                                            ))}
                                            <ul>
                                                {bulletPoints.map((point, index) => (
                                                    <li key={index} style={{ lineHeight: "1.8" }}>{point}</li>
                                                ))}
                                            </ul>
                                            {additionalInfo.map((info, index) => (
                                                <p key={index}>{info}</p>
                                            ))}
                                        </div>
                                        <div className="wpo-project-single-content-des-right">
                                            <ul>
                                                <li>Location: <span>{location}</span></li>
                                                <li>Date: <span>{date}</span></li>
                                                <li>Duration: <span>{duration}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
                /* Styles for Image and Content */
                .wpo-project-single-content img {
                    width: 100%;
                    height: auto;
                    border-radius: 8px;
                    margin-bottom: 20px;
                }

                .wpo-project-single-content-des-left h2 {
                    color: #004080;
                    font-size: 2rem;
                    margin-bottom: 15px;
                }

                /* Styling for Bullet Points and Text */
                .wpo-project-single-content-des-left ul {
                    list-style-type: disc;
                    padding-left: 20px;
                    color: #333;
                    font-size: 1rem;
                }
                
                .wpo-project-single-content-des-left ul li {
                    margin-bottom: 8px;
                }
                
                /* Padding for Section */
                .section-padding {
                    padding: 60px 0;
                }
            `}</style>
        </section>
    );
};

export default ServiceSingleSection;
