import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo2.png';

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div
            className="row"
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {/* Left section with Logo and About text */}
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title" style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                  <Link onClick={ClickHandler} to="/">
                    <img src={Logo} alt="blog" style={{ maxWidth: '100%' }} />
                  </Link>
                </div>
                <p>
                Empowering success with tailored training and consulting, elevating skills, driving growth, and transforming businesses.

                </p>
              </div>
            </div>

            {/* Middle section with Contact information */}
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact</h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i> 7 Green Lake Street
                      Crawfordsville, IN 47933
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i> +968 9978 5598
                    </li>
                    <li>
                      <i className="fi flaticon-phone-call"></i> +962 7 9738 6045
                    </li>
                    <li>
                      <i className="fi flaticon-send"></i> info@mafaz.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Right section with Follow Us and Social Media icons */}
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget social-widget">
                <div className="widget-title">
                  <h3>Follow Us</h3>
                </div>
                <ul className="social-links" style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
  <a href="https://www.linkedin.com/company/mafaz-tc/" target="_blank" rel="noopener noreferrer">
    <i className="ti-linkedin"></i>
  </a>
</li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12" style={{ textAlign: 'center' }}>
              <p className="copyright">
                Copyright &copy; 2024 by{' '}
                <Link onClick={ClickHandler} to="/home">
                  Mafaz
                </Link>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
