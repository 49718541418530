import React from 'react';

const ThirdServiceSection = () => {
    return (
        <section className="coming-soon-section">
            <div className="content">
                <h1>We're Coming Soon</h1>
                <p>Something exciting is in the works! Stay tuned for updates as we prepare to launch.</p>
            </div>

            <style>{`
                .coming-soon-section {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // min-height: calc(100vh - 100px); /* Reduces height by 100px */
                    background: #fff;
                    color: #333;
                    text-align: center;
                    padding: 50px 0; /* Adds 50px padding to top and bottom */
                }

                .content {
                    max-width: 600px;
                    text-align: center;
                }

                h1 {
                    font-size: 3rem;
                    margin: 0 0 20px;
                    color: #004080;
                }

                p {
                    font-size: 1.2rem;
                    color: #555;
                }
            `}</style>
        
        
        </section>
    );
}

export default ThirdServiceSection;
