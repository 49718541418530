// src/components/ErrorBoundary.js
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log error to console or error tracking service
    console.error("Error caught by ErrorBoundary:", error, info);
  }

  render() {
    if (this.state.hasError) {
      // Redirect to the 404 page
      return <Navigate to="/404" replace />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
