import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import ThirdServiceSection from '../../components/ThirdServiceSection'

const Conferencespage =() => {
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={'Conferences'} pagesub={'Services'}/> 
            <ThirdServiceSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default Conferencespage;