import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Homepage2 from "../HomePage2";
import AboutPage from "../AboutPage";
import Trainingplanpage from "../Services/Trainingplan";
import Forumspage from "../Services/Forums";
import Conferencespage from "../Services/Conferences";

import Clientspage from "../Clientspage";
import TestimonialPage from "../TestimonialPage";
import ErrorPage from "../ErrorPage";
import ContactPage from "../ContactPage";
import TrainingPlan from "../BlogPage";
import Media from "../Media";
import ErrorBoundary from "../../components/ErrorBoundary";
// import SearchResults from "../Search"; // Import SearchResults

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <ErrorBoundary>
          <Routes>
            <Route exact path="/" element={<Homepage2 />} />
            <Route path="/home" element={<Homepage2 />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/trainingplan" element={<TrainingPlan />} />
            <Route path="/media" element={<Media />} />
            <Route path="/trainingprograms" element={<Trainingplanpage />} />
            <Route path="/forums" element={<Forumspage />} />
            <Route path="/conferences" element={<Conferencespage />} />
            {/* <Route path="/search" element={<SearchResults />} /> Search route */}
            <Route path="/clients" element={<Clientspage />} />
            <Route path="/testimonials" element={<TestimonialPage />} />
            <Route path="/404" element={<ErrorPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
