import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

class Hero2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0, // State to track the current slide index
        };
    }

    handleAfterChange = (current) => {
        this.setState({ currentSlide: current }); // Update current slide index
    };

    render() {
        const sliderContainerStyle = {
            width: '100%',
            height: '350px', // Set a fixed height for the container
            position: 'relative',
            overflow: 'hidden',
        };

        const fitImageStyle = {
            width: '100%',
            maxHeight: '350px', // Set maximum height to maintain uniformity
            objectFit: 'cover', // Cover the area without stretching the image
        };

        // Slider settings
        const settings = {
            dots: true, // Enable dots
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            adaptiveHeight: false, // Disable adaptive height to keep fixed height
            afterChange: this.handleAfterChange, // Handle afterChange event
            appendDots: dots => (
                <div style={{ bottom: '10px', padding: "10px", position: 'absolute', width: '100%', textAlign: 'center' }}>
                    <ul style={{ margin: "0px", listStyleType: 'none' }}>{dots}</ul>
                </div>
            ),
            customPaging: (i) => (
                <div className={`custom-dot ${this.state.currentSlide === i ? 'active' : ''}`}></div> // Dynamically set active class
            ),
        };

        return (
            <div className="right-img">
                <div className="right-img2" style={sliderContainerStyle}>
                    <Slider {...settings}>
                        <div>
                            <img src={require('../../images/image1.png')} alt="Image 1" style={fitImageStyle} />
                        </div>
                        <div>
                            <img src={require('../../images/image2.png')} alt="Image 2" style={fitImageStyle} />
                        </div>
                        <div>
                            <img src={require('../../images/image3.png')} alt="Image 3" style={fitImageStyle} />
                        </div>
                    </Slider>
                </div>
                <style>{`
                    .slick-dots {
                        bottom: 10px; /* Position of dots */
                    }
                    .slick-dots li {
                        margin: 0 5px; /* Spacing between dots */
                    }
                    .slick-dots li button {
                        display: none; /* Hide the default button */
                    }
                    .custom-dot {
                        background: gray; /* Inactive dot color */
                        border-radius: 50%;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                    }
                    .custom-dot.active {
                        background: blue; /* Active dot color */
                    }
                `}</style>
            </div>
        );
    }
}

export default Hero2;
