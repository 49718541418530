import React, { useState, useEffect } from 'react';
import './MediaGallery.css';




// Full list of image imports based on the filenames provided
import img1 from '../../images/gallery/063A4162.JPG';
import img2 from '../../images/gallery/063A4164.JPG';
import img3 from '../../images/gallery/063A4175.JPG';
import img4 from '../../images/gallery/063A4200.JPG';
import img5 from '../../images/gallery/063A4202.JPG';
import img6 from '../../images/gallery/063A4217.JPG';
import img7 from '../../images/gallery/063A4218.JPG';
import img8 from '../../images/gallery/063A4231.JPG';
import img9 from '../../images/gallery/063A4238.JPG';
import img10 from '../../images/gallery/063A4242.JPG';
import img11 from '../../images/gallery/063A4243.JPG';
import img12 from '../../images/gallery/063A4244.JPG';
import img13 from '../../images/gallery/063A4247.JPG';
import img14 from '../../images/gallery/063A4249.JPG';
import img15 from '../../images/gallery/063A4252.JPG';
import img16 from '../../images/gallery/063A4255.JPG';
import img17 from '../../images/gallery/063A4258.JPG';
import img18 from '../../images/gallery/063A4267.JPG';
import img19 from '../../images/gallery/063A4275.JPG';
import img20 from '../../images/gallery/063A4279.JPG';
import img21 from '../../images/gallery/063A4280.JPG';
import img22 from '../../images/gallery/063A4286.JPG';
import img23 from '../../images/gallery/063A4292.JPG';
import img24 from '../../images/gallery/063Ax4272.JPG';
import img25 from '../../images/gallery/20240128_083234 (1).jpg';
import img26 from '../../images/gallery/20240128_083324.jpg';
import img27 from '../../images/gallery/20240129_121735.jpg';
import img28 from '../../images/gallery/20240131_124308 (1).jpg';
import img29 from '../../images/gallery/20240131_125042.jpg';
import img30 from '../../images/gallery/20240131_125747.jpg';
import img31 from '../../images/gallery/20240304_122330.jpg';
import img32 from '../../images/gallery/20240304_122352.jpg';
import img33 from '../../images/gallery/20240304_122401 (1).jpg';
import img34 from '../../images/gallery/20240305_100212.jpg';
import img35 from '../../images/gallery/20240305_103059 (1).jpg';
import img36 from '../../images/gallery/20240430_104726.jpg';
import img37 from '../../images/gallery/20240808_120426.jpg';
import img38 from '../../images/gallery/20240908_082658.jpg';
import img39 from '../../images/gallery/20240908_082737.jpg';
import img40 from '../../images/gallery/20240908_082909 (6).jpg';
import img41 from '../../images/gallery/20240908_090543 (1).jpg';
import img42 from '../../images/gallery/IMG-20240501-WA0001.jpg';
import img43 from '../../images/gallery/IMG-20240501-WA0003.jpg';
import img44 from '../../images/gallery/IMG-20240501-WA0004.jpg';
import img45 from '../../images/gallery/IMG-20240731-WA0013.jpg';
import img46 from '../../images/gallery/IMG-20240731-WA0015.jpg';
import img47 from '../../images/gallery/IMG-20240731-WA0019.jpg';
import img48 from '../../images/gallery/IMG-20240808-WA0052.jpg';
import img49 from '../../images/gallery/IMG-20240909-WA0045.jpg';
import img50 from '../../images/gallery/IMG-20240909-WA0049.jpg';
import img51 from '../../images/gallery/IMG-20241009-WA0064.jpg';
import img52 from '../../images/gallery/IMG-20241030-WA0003.jpg';
import img53 from '../../images/gallery/IMG-20241030-WA0006.jpg';
import img54 from '../../images/gallery/IMG-20241030-WA0007.jpg';
import img55 from '../../images/gallery/IMG-20241030-WA0020 (1).jpg';
import img56 from '../../images/gallery/IMG-20241030-WA0023.jpg';


const imageImports = [
  img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
  img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
  img21, img22, img23, img24, img25, img26, img27, img28, img29, img30,
  img31, img32, img33, img34, img35, img36, img37, img38, img39, img40,
  img41, img42, img43, img44, img45, img46, img47, img48, img49, img50,
  img51, img52, img53, img54, img55, img56
];

const MediaGallery = () => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [randomImages, setRandomImages] = useState([]);

  useEffect(() => {
    // Shuffle and select 9 images
    const shuffledImages = imageImports.sort(() => 0.5 - Math.random()).slice(0, 9);
    setRandomImages(shuffledImages);
  }, []);

  return (
    <section className="media-gallery-section">
      <div className="container">
        <div className="gallery-header">
          <h2>Our Media Gallery</h2>
          <p>Explore some highlights from our recent activities and events.</p>
        </div>
        <div className="gallery-grid">
          {randomImages.map((img, index) => (
            <div className="gallery-item" key={index} onClick={() => setSelectedImg(img)}>
              <img src={img} alt={`Gallery ${index + 1}`} className="gallery-img" />
            </div>
          ))}
        </div>
        {selectedImg && (
          <div className="lightbox" onClick={() => setSelectedImg(null)}>
            <span className="close">&times;</span>
            <img className="lightbox-content" src={selectedImg} alt="Enlarged view" />
          </div>
        )}
      </div>
    </section>
  );
};

export default MediaGallery;


