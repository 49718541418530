import React from 'react';
import prjImg1 from '../../images/clients/bankdhofar.jpg';
import prjImg2 from '../../images/clients/commerce.jpg';
import prjImg3 from '../../images/clients/defenseministry.png';
import prjImg4 from '../../images/clients/education.png';
import prjImg5 from '../../images/clients/foreign.png';
import prjImg6 from '../../images/clients/highereducation.jpeg';
import prjImg7 from '../../images/clients/housing.png';
import prjImg8 from '../../images/clients/madayn.jpg';
import prjImg9 from '../../images/clients/media.jpeg';
import prjImg10 from '../../images/clients/msx.png';
import prjImg11 from '../../images/clients/nama.jpg';
import prjImg12 from '../../images/clients/nbo.png';
import prjImg13 from '../../images/clients/omanicourt.jpg';
import prjImg14 from '../../images/clients/omanioil.png';
import prjImg15 from '../../images/clients/protectionfund.png';
import prjImg16 from '../../images/clients/royalguard.jpg';
import prjImg17 from '../../images/clients/police.jpg';
import prjImg18 from '../../images/clients/taxomani.jpeg';
import prjImg19 from '../../images/clients/TIE Logo small.png';
import prjImg20 from '../../images/clients/youth.png';

const AllClients = () => {
    const projects = [
        { pImg: prjImg13, title: 'Diwan of Royal Court' },
        { pImg: prjImg2, title: 'Ministry of Commerce, Industry & Investment Promotion' },
        { pImg: prjImg3, title: 'Ministry of Defense' },
        { pImg: prjImg4, title: 'Ministry of Education' },
        { pImg: prjImg5, title: 'Foreign Ministry' },
        { pImg: prjImg6, title: 'Ministry of Higher Education, Scientific Research and Innovation' },
        { pImg: prjImg7, title: 'Ministry of Housing and Urban Planning' },
        { pImg: prjImg8, title: 'Madayn' },
        { pImg: prjImg9, title: 'Ministry of Media' },
        { pImg: prjImg10, title: 'MSX' },
        { pImg: prjImg11, title: 'Water Services' },
        { pImg: prjImg12, title: 'National Bank of Oman' },
        { pImg: prjImg1, title: 'Bank Dhofar' },
        { pImg: prjImg14, title: 'Oman Oil' },
        { pImg: prjImg15, title: 'Social Protection Fund' },
        { pImg: prjImg16, title: 'Royal Guard of Oman' },
        { pImg: prjImg17, title: 'Royal Oman Police' },
        { pImg: prjImg18, title: 'Tax Authority' },
        { pImg: prjImg19, title: 'Trading & Investment establishment' },
        { pImg: prjImg20, title: 'Ministry of Culture, Sports and Youth' },
    ];

    return (
        <section className="wpo-gallery-section wpo-gallery-section-s2 section-padding">
            <style>
                {`
                    .wpo-gallery-item {
                        margin: 15px;
                        flex: 1 1 calc(33% - 20px);
                        box-sizing: border-box;
                    }

                    .wpo-gallery-container {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }

                    .wpo-gallery-item img {
                        width: 100%;
                        height: auto;
                    }

                    .wpo-gallery-text h3 {
                        font-size: 16px;
                        text-align: center;
                    }
                `}
            </style>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12 sortable-gallery">
                        <div className="wpo-gallery-container">
                            {projects.map((project, index) => (
                                <div className="grid" key={index}>
                                    <div className="wpo-gallery-item">
                                        <img src={project.pImg} alt={project.title} className="img img-responsive" />
                                        <div className="wpo-gallery-text">
                                            <h3>{project.title}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AllClients;
