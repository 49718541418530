import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import AllClients from '../../components/Clientpage'; // Correct import

const Clientspage = () => {
    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={'Our Clients'} pagesub={'Clients'} /> 
            <AllClients /> {/* Capitalize component name */}
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default Clientspage;
