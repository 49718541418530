import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonial extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonial = [
            {
                Des: "We hope that workshops like this one and with the same esteemed instructors will continue.",
                Title: 'Trainee',
                Sub: "Sports Leaders Center",
            },
            {
                Des: "Increasing such programs would have a positive impact on employee behavior and performance.",
                Title: 'Trainee',
                Sub: "Sports Leaders Center",
            },
            {
                Des: "We thank the trainer for his efforts and the excellent program.",
                Title: 'Trainee',
                Sub: "Sports Leaders Center",
            },
            {
                Des: "We hope to keep continuing and intensifying such courses.",
                Title: 'Trainee',
                Sub: "Sports Leaders Center",
            },
            {
                Des: "The course was excellent.",
                Title: 'Trainee',
                Sub: "Sports Leaders Center",
            },
            {
                Des: "In this workshop, I explored who I am as a leader, gaining clarity on my strengths and weaknesses. I learned valuable skills for building team unity, leading effectively, delegating tasks, and recognizing the leadership potential in everyone",
                Title: 'Director of the Theater and Cinema Department',
                Sub: "Ministry of Sports",
            },
            {
                Des: "This course exceeded expectations. The training center staff were very cooperative, and we gained a lot of valuable knowledge from the course material. ",
                Title: 'Trainee',
                Sub: "Tax Authority",
            },
            {
                Des: "These courses should be offered more frequently because of the benefits they provide in employee development. We thank the training organization and the trainer for this course.",
                Title: 'Trainee',
                Sub: "Tax Authority",
            }

        ];

        return (
            <section className={`wpo-testimonial-section section-padding ${this.props.tClass}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="wpo-testimonial-title">
                                <h2><i className="fi flaticon-left-quote"></i></h2>
                                
                                <h2>What <span>Our Clients</span> are Saying</h2>
                            </div>
                        </div>
                    </div>
                    <div className="wpo-testimonial-items wpo-testimonial-slider owl-carousel">
                        <Slider {...settings}>
                            {testimonial.map((tesmnl, tsm) => (
                                <div className="wpo-testimonial-item" key={tsm}>
                                    <div className="wpo-testimonial-text">
                                        <p>{tesmnl.Des}</p>
                                        <div className="wpo-testimonial-text-btm">
                                            <h3>{tesmnl.Title}</h3>
                                            <span>{tesmnl.Sub}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonial;
