import React from 'react';
import { Link } from 'react-router-dom';

import prdimg1 from '../../images/training-programs.png';
import prdimg2 from '../../images/group-discussion.png';
import prdimg3 from '../../images/conference.png';

const Features = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const featres = [
        {
            fIcon: prdimg1,
            title: 'Training Programs',
            des: 'Customized training programs focused on skill development and professional growth for individuals and teams.',
            link: '/trainingprograms',
        },
        {
            fIcon: prdimg2,
            title: 'Forums',
            des: 'Interactive forums for knowledge exchange and networking among professionals and experts.',
            link: '/forums',
        },
        {
            fIcon: prdimg3,
            title: 'Conferences',
            des: 'Engaging conferences that bring together industry leaders to share insights, trends, and best practices.',
            link: '/Conferences',
        },
    ];

    const whiteBgStyle = {
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
    };

    return (
        <section className={`wpo-features-section section-padding ${props.featuresClass}`}>
            <div className="container">
                <div className="row">
                    {featres.map((featres, fitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={fitem}>
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon" style={whiteBgStyle}>
                                    <div className="icon">
                                        <img src={featres.fIcon} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2><Link onClick={ClickHandler} to={featres.link}>{featres.title}</Link></h2>
                                    <p>{featres.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Features;
