import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Import images
import prjImg1 from '../../images/service1.jpg';
import prjImg2 from '../../images/audit.jpg';
import prjImg3 from '../../images/leadership.jpg';
import prjImg4 from '../../images/emergency.jpg';
import prjImg5 from '../../images/ai.jpg';
import prjImg6 from '../../images/tax.jpg';

// Project data array
const projects = [
    {
        image: prjImg1,
        title: "The Integrated System Program for Building and Managing High-Performance Teams",
        description: [
            "This program was conducted to equip participants with the knowledge, skills, and behaviors that enhance the efficiency and effectiveness of committees and work teams to achieve the institution's goals and the desired excellence and leadership. This aligns with modern management trends to achieve optimal performance and sustain outstanding results. A select group of managers from an Omani ministry participated in the program."
        ],
        location: "Muscat, Sultanate of Oman",
        date: "7 Oct 2024",
        duration: "2 Days",
    },
    {
        image: prjImg2,
        title: "Modern Technologies in Internal Auditing",
        description: [
            "This training program aims to enhance internal auditors' understanding across various sectors of the core concepts of internal auditing and to develop their skills in using modern technologies in audit practices. It focuses on improving the accuracy and integrity of audit results, expanding risk coverage, and enabling participants to identify emerging risks more quickly. The program will also cover the latest technologies used in internal auditing, such as digital auditing, data analysis, machine learning, artificial intelligence, continuous auditing, Computer-Assisted Audit Tools (CAATs), risk-based auditing, and the application of artificial intelligence techniques in various auditing fields.",
        ],
        location: "Salalah, Sultanate of Oman",
        date: "4 Aug 2024",
        duration: "5 Days",
    },
    {
        image: prjImg3,
        title: "Professional Leadership and Facing Challenges",
        description: [
            "Given the need for administrative and leadership position holders to be equipped with advanced leadership skills that allow them to lead their institutions, departments, and divisions with utmost professionalism, especially amid global competition, the dominance of globalization, and emerging challenges, this program aims to help achieve exceptional institutions where creativity is the approach and excellence is a hallmark throughout the organization and across all employees at various levels. Believing that the success of institutions stems from the success of their leaders, this program is directed at leaders to help them achieve the desired goals.",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "3 Mar 2024",
        duration: "3 Days",
    },
    {
        image: prjImg4,
        title: "Emergency Planning and Crisis Management",
        description: [
            "The Emergency Planning and Crisis Management course provides essential tools and knowledge to help employees in critical sectors act effectively and swiftly during crises, thereby reducing damage and ensuring the safety of facilities and individuals. Participants will learn how to assess risks, develop comprehensive response strategies, and coordinate efforts across different teams to ensure service continuity and protect the community. Through practical training and specialized theoretical content, participants will gain an understanding of the importance of emergency planning and its effective application within their institutions. ",
            "The benefits of this course go beyond enhancing participants' crisis management capabilities; it also fosters an institutional culture rooted in preparedness and planning, enabling participants to transfer acquired knowledge and skills to their teams. This strengthens the overall readiness of the institution and ensures the continuity of vital services even in the most challenging circumstances. In summary, this course is an investment in safety and institutional stability, positively impacting the community as a whole."
        ],
        location: "Muscat, Sultanate of Oman",
        date: "28 Jul 2024",
        duration: "4 Days",
    },
    {
        image: prjImg5,
        title: "Utilizing Artificial Intelligence in the Workplace",
        description: [
            "This program serves as an awareness initiative on integrating artificial intelligence in the workplace, attended by a group of employees and managers from various administrative levels of a governmental entity. The program aims to help participants differentiate between all types of artificial intelligence, understand its various applications, identify the fields where AI can operate, recognize AI applications upon seeing them, analyze their working mechanisms, and select future study areas in AI.",
            "The program covered several topics, including:"
        ],
        bulletPoints: [
            "The benefits and risks associated with using artificial intelligence in organizations and governmental institutions.",
            "The Global Information Preservation System.",
            "Ethics in dealing with artificial intelligence.",
            "Correlation versus causation.",
            "The random sampling system to establish necessary standards.",
            "Web data analysis and mediator models."
        ],
        location: "Muscat, Sultanate of Oman",
        date: "8 September 2024",
        duration: "3 Day",
    },
    {
        image: prjImg6,
        title: "Techniques of Tax Evasion in Value Added Tax and Excise Tax",
        description: [
            "The program targeted a group of auditors working in the tax sector to equip them with skills in calculating VAT, conducting tax audits, and identifying tax evasion and avoidance. It covered essential tax concepts related to VAT and excise tax, types of direct and indirect taxes, and the tax systems of different countries. Topics included tax declarations, procedures for filing, amending, and assessing, the importance and limits of tax auditing, preparation steps for tax audits, decision approval standards, violations and penalties, calculating VAT on external purchases and its relation to tax evasion, reasons and motivations for tax evasion, methods and means of tax evasion, and ways to combat it. The program emphasized tax auditing and examination requirements, the importance of the Tax Evasion Control Unit and its role in gathering information and investigation, and more. It also included practical applications and case studies on VAT for goods and services."
        ],
        location: "Muscat, Sultanate of Oman",
        date: "28 Jan 2024",
        duration: "6 Days",
    },
];

const ServiceSingleSection = () => {
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

    // Functions to handle previous and next project
    const handlePreviousProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex === 0 ? projects.length - 1 : prevIndex - 1));
        window.scrollTo(10, 0);
    };

    const handleNextProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
        window.scrollTo(10, 0);
    };

    // Current project details
    const { image, title, description, bulletPoints, location, date, duration } = projects[currentProjectIndex];

    return (
        <section className="wpo-project-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-content">
                                <img src={image} alt="Project" />
                                <div className="wpo-project-single-content-des">
                                    <div className="wpo-project-single-content-des-wrap">
                                        <div className="wpo-project-single-content-des-left">
                                            <h2>{title}</h2>
                                            {description.map((para, index) => (
                                                <p key={index}>{para}</p>
                                            ))}
                                            {bulletPoints && (
                                                <ul>
                                                    {bulletPoints.map((point, index) => (
                                                        <li key={index} style={{ lineHeight: "1.8" }}>{point}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="wpo-project-single-content-des-right">
                                            <ul>
                                                <li>Location: <span>{location}</span></li>
                                                <li>Date: <span>{date}</span></li>
                                                <li>Duration: <span>{duration}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Stylish Previous and Next Buttons */}
                            <div className="more-posts">
                                <div className="previous-post">
                                    <button onClick={handlePreviousProject} className="arrow-btn prev-btn">
                                        <span>&larr; Previous</span>
                                    </button>
                                </div>
                                <div className="next-post">
                                    <button onClick={handleNextProject} className="arrow-btn next-btn">
                                        <span>Next &rarr;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
                /* Stylish Button Styles */
                .arrow-btn {
                    background: linear-gradient(145deg, #003366, #004080); /* Gradient background */
                    color: white;
                    border: none;
                    padding: 12px 24px;
                    border-radius: 25px;
                    font-size: 16px;
                    font-weight: 600;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                    box-shadow: 0px 6px 18px rgba(0, 51, 102, 0.4); /* Enhanced shadow */
                }

                .arrow-btn:hover {
                    transform: translateY(-2px); /* Lift effect on hover */
                    background: linear-gradient(145deg, #004080, #003366); /* Slight gradient shift */
                    box-shadow: 0px 8px 22px rgba(0, 51, 102, 0.5); /* Stronger shadow on hover */
                }

                .arrow-btn:active {
                    transform: scale(0.98); /* Slight press effect on click */
                }

                .prev-btn {
                    margin-right: auto;
                }

                .next-btn {
                    margin-left: auto;
                }

                /* Container Styling for Flex Alignment */
                .more-posts {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }
                
                /* Bullet List Styles */
                .wpo-project-single-content-des-left ul {
                    list-style-type: disc;
                    padding-left: 20px;
                    color: #333;
                    font-size: 1rem;
                }
                
                .wpo-project-single-content-des-left ul li {
                    margin-bottom: 8px;
                }
            `}</style>
        </section>
    );
};

export default ServiceSingleSection;
