import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled component for the WhoWeAre section
const WhoWeAreSection = styled.section`
  padding: 80px 20px;
  background-color: #f4f7fc;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 20px auto; /* Margin auto to center and 20px below for spacing */
  line-height: 1.8;
`;

const WhoWeAre = () => {
  return (
    <WhoWeAreSection>
      <Title>Welcome to Mafaz</Title>
      <Description>
        We are a leading training and consulting firm dedicated to fostering institutional excellence and performance development. Our mission is to be the preferred choice for organizations seeking to enhance their capabilities and achieve comprehensive quality.
      </Description>
    </WhoWeAreSection>
  );
};

export default WhoWeAre;
