import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2';
import PageTitle from '../../components/pagetitle';
import About from '../../components/about';
import FunFact from '../../components/FunFact';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={'About Us'} pagesub={'Who We Are'} />
      <About abClass={'wpo-about-section-s2'} />
      <FunFact fnClass={'section-padding'} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default AboutPage;

