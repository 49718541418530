import React from 'react';

const FunFact = (props) => {
  const funfact = [
    {
      title: '90%',
      subTitle: 'Client Satisfaction',
    },
    {
      title: '30+',
      subTitle: 'Trained Professionals',
    },
    
    {
      title: '85%',
      subTitle: 'Repeat clients',
    },
  ];

  return (
    <section className={`wpo-fun-fact-section ${props.fnClass}`}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div
              className="wpo-fun-fact-grids clearfix"
              style={{
                display: 'flex',
                justifyContent: 'center', // Centers items horizontally
                alignItems: 'center', // Centers items vertically
                gap: '40px', // Adds space between items
              }}
            >
              {funfact.map((funfact, fitem) => (
                <div className="grid" key={fitem}>
                  <div className="info" style={{ textAlign: 'center' }}> {/* Center text inside each grid */}
                    <h3>{funfact.title}</h3>
                    <p>{funfact.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;