import React from "react";
import Header2 from '../header2';

export default function Navbar2() {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    setScroll(scrollTop);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Define the navbar style
  const navbarStyle = {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
    transition: "top 0.4s ease",
  };

  // Define the height of the navbar for spacing
  const navbarHeight = "60px"; // Adjust this value based on your navbar's actual height

  return (
    <>
      {/* Navbar */}
      <div style={navbarStyle}>
        <Header2 />
      </div>

      {/* Spacer div to push content down */}
      <div style={{ height: navbarHeight }} />

      {/* Rest of your page content */}
      <div>
        {/* Your main content goes here */}
      </div>
    </>
  );
}
