import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Styled components for an appealing layout
const Section = styled.section`
  padding: 80px 20px;
  background-color: #f4f7fc;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const SubTitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 600;
  color: #003366; /* Match the color to the Vision title */
  margin-bottom: 40px;
`;

const CompanyDescription = styled.p`
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.4rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 60px;
`;

const TeamSection = styled.div`
  margin-bottom: 80px;
`;

const TeamTitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 600;
  color: #003366;
  margin-bottom: 40px;
`;

const TeamGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
`;

const TeamCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 300px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const TeamImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`;

const TeamName = styled.h4`
  font-size: 1.5rem;
  color: #003366;
  margin-bottom: 10px;
`;

const TeamRole = styled.p`
  font-size: 1.1rem;
  color: #777;
`;

const CallToAction = styled.div`
  background-color: #e9ecf5;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
  margin-top: 60px;
  border-radius: 8px;
`;

const CTAButton = styled(Link)`
  background-color: #ff9900;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none; /* Ensure the link looks like a button */
  display: inline-block; /* Make the link behave like a button */

  &:hover {
    background-color: #cc7a00;
  }
`;

// New styled components for Mission, Vision, and Goals
const MissionVisionGoalsSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 80px 0;
  gap: 40px;
`;

const MissionVisionGoalsCard = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 300px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`;

const CardTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  color: #003366;
  margin-bottom: 20px;
`;

const CardDescription = styled.div`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;

  ul {
    list-style-type: disc;
    padding-left: 20px; /* Add padding to indent the bullets */
  }

  li {
    margin-bottom: 10px; /* Space out each bullet point */
  }
`;


const AboutPage = () => {
  return (
    <Section>
      <Container>
        <SubTitle>Your Trusted Partner for Institutional Excellence</SubTitle>
        {/* <CompanyDescription>
        We are a consulting and training firm dedicated to advancing organizations across both public and private sectors. Our mission is to drive sustainable growth, foster innovation, and address complex challenges, enabling institutions to realize their full potential. With deep expertise in institutional development, we empower organizations to excel, enhance operational efficiency, and establish new benchmarks of excellence.
        </CompanyDescription> */}

        {/* Mission, Vision, and Goals Section */}
        <MissionVisionGoalsSection>
          <MissionVisionGoalsCard>
            <CardTitle>Mission</CardTitle>
            <CardDescription>
            Our mission is to elevate training standards, competencies, and curricula to exceed industry norms across all professions. We are committed to delivering superior consulting, training, and technical administrative development services. Our aim is to enhance service quality, help achieve organizational goals, and prepare clients to meet evolving market demands and future challenges with a dedicated and exceptional team.
            </CardDescription>
          </MissionVisionGoalsCard>
          <MissionVisionGoalsCard>
            <CardTitle>Vision</CardTitle>
            <CardDescription>
            Our vision is to become a premier institution recognized for excellence in fostering institutional development and enhancing performance across diverse sectors. We aim to be the preferred choice for organizations seeking comprehensive capacity building and the achievement of exceptional quality standards.
            </CardDescription>
          </MissionVisionGoalsCard>
          <MissionVisionGoalsCard>
            <CardTitle>Goals</CardTitle>
            <CardDescription>
              <ul>
                <li>To Extend training and advisory services to encompass all sectors across the Arab world.</li>
                <li>Strive to Increase the performance and success rates of both public and private sector organizations.</li>
                <li>To develop local and international collaborations to elevate workforce competencies and broaden professional networks.</li>
              </ul>
            </CardDescription>
          </MissionVisionGoalsCard>
        </MissionVisionGoalsSection>

        
        {/* // Commenting out the "Meet Our Team" section 
        <TeamSection>
          <TeamTitle>Meet Our Team</TeamTitle>
          <TeamGrid>
            <TeamCard>
              <TeamName>Abeer Alhroub</TeamName>
              <TeamRole>CEO & Founder</TeamRole>
            </TeamCard>
            <TeamCard>
              <TeamName>Lina Alhroub</TeamName>
              <TeamRole>Chief Operations Officer</TeamRole>
            </TeamCard>
            <TeamCard>
              <TeamName>Hani Abdulhaq</TeamName>
              <TeamRole>Head of Consulting</TeamRole>
            </TeamCard>
          </TeamGrid>
        </TeamSection> */}
       

        {/* Call to Action */}
        <CallToAction>
          <h2>Ready to take your organization to the next level?</h2>
          <p>Contact us today to learn more about our consulting and training solutions.</p>
          <Link className="theme-btn" to="/contact">Get in Touch</Link>
        </CallToAction>
      </Container>
    </Section>
  );
};

export default AboutPage;
