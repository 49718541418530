import React, { useEffect } from 'react';
import ContactForm from '../ContactFrom';

import cnt1 from '../../images/icon/home.svg';
import cnt2 from '../../images/icon/mail-2.svg';
import cnt3 from '../../images/icon/app.svg';

const ContactPage = () => {
  // Scroll to the top of the page on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="wpo-contact-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1">
            <div className="office-info">
              <div className="row">
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt1} alt=""/>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Address</h2>
                      <p>7 Green Lake Street Crawfordsville, IN 47933</p>
                    </div>
                  </div>
                </div> 
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt2} alt=""/>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Email Us</h2>
                      <p>info@mafaztc.com</p>
                      <br></br>
                    </div>
                  </div>
                </div> 
                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                  <div className="office-info-item">
                    <div className="office-info-icon">
                      <div className="icon">
                        <img src={cnt3} alt=""/>
                      </div>
                    </div>
                    <div className="office-info-text">
                      <h2>Call Now</h2>
                      <p>+968 9978 5598 <br></br>+962 7 9738 6045</p>
                      {/* <p>+962 7 9738 6045</p> */}
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <div className="wpo-contact-title">
              <h2>Have Any Questions?</h2>
            </div>
            <div className="wpo-contact-form-area">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <section className="wpo-contact-map-section">
        <div className="wpo-contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14259.102869214218!2d58.554322199999996!3d23.58592945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8f5d6f9f6d45ef%3A0x2a934df582de6b11!2sMuscat%2C%20Oman!5e0!3m2!1sen!2som!4v1698765432100"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Map of Muscat, Oman"
        />
        </div>
      </section>
      <style>{`
        .office-info-item {
          padding: 20px;
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
          transition: box-shadow 0.3s ease; /* Smooth shadow effect */
        }

        .office-info-item:hover {
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
        }

        .office-info-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
        }

        .office-info-text h2 {
          color: #004080;
          margin: 0 0 10px;
        }

        .office-info-text p {
          color: #555;
        }

        .wpo-contact-title {
          text-align: center;
          margin-top: 40px;
          margin-bottom: 40px;
        }
      `}</style>
    </section>
  );
};

export default ContactPage;
